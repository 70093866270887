<template>
  <div ref="extended-info" class="extended-info">
    <div ref="card1" class="extended-info__card">
      <div class="extended-info__container">
        <img src="/icons/card/comments.svg" class="extended-info__icon" />

        <p class="extended-info__text">
          количество комментариев
          <span>{{ setDate }}</span> -
          <span v-if="checkInfoVisible(groupInfo?.eye_data?.counters?.activity_comments)" class="">
            {{ groupInfo?.eye_data?.counters?.activity_comments }}
          </span>
          <img v-else src="/icons/table/lock.svg" class="image-size" />
        </p>
      </div>
    </div>

    <div ref="card2" class="extended-info__card">
      <div class="extended-info__container">
        <img src="/icons/card/repost.svg" class="extended-info__icon" />

        <p class="extended-info__text">
          количество репостов <span>{{ setDate }}</span> -
          <span v-if="checkInfoVisible(groupInfo?.eye_data?.counters?.activity_copies)" class="">
            {{ groupInfo?.eye_data?.counters?.activity_copies }}
          </span>
          <img v-else src="/icons/table/lock.svg" class="image-size" />
        </p>
      </div>
    </div>

    <div ref="card3" class="extended-info__card">
      <div class="extended-info__container">
        <img src="/icons/card/hidden.svg" class="extended-info__icon" />

        <p class="extended-info__text">
          количество пользователей, скрывших записи из новостей <span>{{ setDate }}</span> -
          <span v-if="checkInfoVisible(groupInfo?.eye_data?.counters?.activity_hidden)" class="">
            {{ groupInfo?.eye_data?.counters?.activity_hidden }}
          </span>
          <img v-else src="/icons/table/lock.svg" class="image-size" />
        </p>
      </div>
    </div>

    <div ref="card4" class="extended-info__card">
      <div class="extended-info__container">
        <img src="/icons/card/like.svg" class="extended-info__icon" />

        <p class="extended-info__text">
          количество отметок «нравится» <span>{{ setDate }}</span> -
          <span v-if="checkInfoVisible(groupInfo?.eye_data?.counters?.activity_likes)" class="">
            {{ groupInfo?.eye_data?.counters?.activity_likes }}
          </span>
          <img v-else src="/icons/table/lock.svg" class="image-size" />
        </p>
      </div>
    </div>

    <div ref="card5" class="extended-info__card">
      <div class="extended-info__container">
        <img src="/icons/card/activity_subscribe.svg" class="extended-info__icon" />

        <p class="extended-info__text">
          количество подписавшихся/отписавшихся пользователей на сообщество
          <span>{{ currentDate }}</span> -
          <span
            v-if="
              checkInfoVisible(groupInfo?.eye_data?.counters?.activity_subscribed) &&
              checkInfoVisible(groupInfo?.eye_data?.counters?.activity_unsubscribed)
            "
            class=""
          >
            {{ groupInfo?.eye_data?.counters?.activity_subscribed }}
            /
            {{ groupInfo?.eye_data?.counters?.activity_unsubscribed }}
          </span>

          <img v-else src="/icons/table/lock.svg" class="image-size" />
        </p>
      </div>
    </div>

    <div ref="card6" class="extended-info__card">
      <div class="extended-info__container">
        <img src="/icons/card/views.svg" class="extended-info__icon" />

        <span class="extended-info__text">
          число просмотров <span>{{ currentDate }}</span> -
          <span v-if="checkInfoVisible(groupInfo?.eye_data?.counters?.visitors_views)" class="">
            {{ groupInfo?.eye_data?.counters?.visitors_views }}
          </span>
          <img v-else src="/icons/table/lock.svg" class="image-size" />
        </span>
      </div>
    </div>

    <div ref="card8" class="extended-info__card">
      <div class="extended-info__container">
        <img src="/icons/card/reach.svg" class="extended-info__icon" />

        <span class="extended-info__text">
          полный охват <span>{{ currentDate }}</span> -
          <span v-if="checkInfoVisible(groupInfo?.eye_data?.counters?.reach_reach)" class="">
            {{ groupInfo?.eye_data?.counters?.reach_reach }}
          </span>
          <img v-else src="/icons/table/lock.svg" class="image-size" />
        </span>
      </div>
    </div>

    <div ref="card10" class="extended-info__card">
      <div class="extended-info__container">
        <img src="/icons/card/mobile_reach.svg" class="extended-info__icon" />

        <span class="extended-info__text">
          охват с мобильных устройств <span>{{ currentDate }}</span> -
          <span v-if="checkInfoVisible(groupInfo?.eye_data?.counters?.reach_mobile_reach)" class="">
            {{ groupInfo?.eye_data?.counters?.reach_mobile_reach }}
          </span>
          <img v-else src="/icons/table/lock.svg" class="image-size" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('vkRequests', ['getCurrentDate']),

    setDate() {
      switch (this.getCurrentDate) {
        case 'day':
          return ' за день ';
        case 'week':
          return ' за неделю ';
        default:
      }
      return ' за месяц ';
    },
  },

  props: {
    groupInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    currentDate: {
      type: String,
      default: 'за день',
    },
  },

  methods: {
    checkInfoVisible(currentValue) {
      return !!currentValue && +currentValue !== 0.1 && currentValue !== '0,1';
    },
  },
};
</script>

<style lang="scss" scoped>

.extended-info {
  display: grid;
  grid-template-columns: repeat(auto-fill, 45%);
  justify-content: space-around;
  align-items: space-around;
  flex-flow: wrap;

  position: relative;

  margin: 25px 25px;

  // .extended-info__card
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;

    margin: 5px 10px;
    padding: 0 0 0 10px;

    background: #5e82a8;
    min-width: 150px;
    min-height: 90px;

    border-radius: 20px;

    z-index: 0;
    position: static;
    transition: all 0.3s ease-out;
  }

  // .extended-info__container
  &__container {
    margin: 10px 0px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  // .extended-info__icon
  &__icon {
    width: 30px;
    height: 30px;
  }

  // .extended-info__text
  &__text {
    margin: 0 8px 0 10px;
    color: #ffffff;
  }

  @media (max-width: 1250px) {
    grid-template-columns: repeat(auto-fill, 500px);
  }
}

.image-size {
  width: 20px;
  margin: 0 0 0 10px;
  vertical-align: middle;
}
</style>
