<template>
  <div class="container">
    <div class="filters">
      <radio-filter
        :buttons="{ list: periode, groupName: 'periode' }"
        title="Период"
        @updateItem="updateItem"
      ></radio-filter>
    </div>
  </div>
</template>

<script>
import RadioFilter from '@/components/common/filter/RadioButtonFilter.vue';

export default {
  components: {
    RadioFilter,
  },

  computed: {},

  props: {
    startValue: {
      type: String,
      default: '',
    },
  },

  emits: ['updateTable'],

  data() {
    return {
      periode: [
        {
          id: 1,
          title: 'День',
          settingName: 'day',
          checked: true,
        },
        {
          id: 2,
          title: 'Неделя',
          settingName: 'week',
          checked: false,
        },
        {
          id: 3,
          title: 'Месяц',
          settingName: 'month',
          checked: false,
        },
      ],
    };
  },
  methods: {
    updateItem(currentId, groupName) {
      this[groupName].forEach((element, index) => {
        if (element.id === currentId) {
          this[groupName][index].checked = true;

          this.$emit('updateTable', element.settingName.toLowerCase());
          return;
        }

        this[groupName][index].checked = false;
      });
    },

    setItemByName(currentName, groupName) {
      this[groupName].forEach((element, index) => {
        if (element.settingName === currentName) {
          this[groupName][index].checked = true;

          return;
        }

        this[groupName][index].checked = false;
      });
    },
  },
  mounted() {
    if (this.startValue !== '') {
      this.setItemByName(this.startValue, 'periode');
    }
  },
};
</script>

<style lang="scss" scoped>
$wigth-cell: 30%;

.container {
  width: 100%;
  height: 100%;
  // background: #f7f7f7;
}

.filters {
  width: 100%;
}
</style>
