<template>
  <div class="group-statistic">
    <div
      class="group-statistic__background"
      @click="$emit('closeWindow', { currentGroup: {}, isShow: false })"
    ></div>

    <div
      :style="{ overflow: isUpdatedPage ? 'hidden' : 'auto' }"
      class="group-statistic__information"
    >
      <a :href="currentGroup?.group_link" target="_blank" class="group-statistic__title">
        {{ currentGroup?.group_name }}
      </a>

      <div class="group-statistic__main main">
        <img :src="currentGroup?.photo_link" alt="" class="main__img" />

        <div class="main__info">
          <div class="main__pannel">
            <p class="main__text">Подписчики</p>
            <span class="main__text">-</span>
            <p class="main__text">{{ currentGroup?.subscrices }}</p>
          </div>

          <div class="main__pannel">
            <p class="main__text">Охват</p>

            <span class="main__text">-</span>

            <a target="_blank" :href="currentGroup?.cov_stat_link" class="link-size main__text">
              <span
                v-if="
                  checkParam(currentGroup?.reach_reach) !== '' && +currentGroup?.reach_reach !== 0
                "
              >
                {{ currentGroup?.coverage }}
              </span>

              <img v-else src="/icons/table/lock.svg" class="image-size" />
            </a>
          </div>

          <div class="main__pannel">
            <p class="main__text">Посетители</p>

            <span class="main__text">-</span>

            <a target="_blank" class="link-size main__text" :href="currentGroup?.vis_stat_link">
              <span
                v-if="
                  checkParam(currentGroup?.visitors_views) !== '' &&
                  +currentGroup?.visitors_views !== 0 &&
                  currentGroup?.visitors !== '0,1'
                "
              >
                {{ currentGroup?.visitors }}
              </span>

              <img v-else src="/icons/table/lock.svg" class="image-size" />
            </a>
          </div>

          <div class="main__pannel">
            <p class="main__text">Прирост</p>

            <span class="main__text">-</span>

            <div v-if="currentGroup?.grow_count !== 0.1" class="main__wrap">
              <arrow-icon :arrow="setArrow(currentGroup)" class="main__arrow"> </arrow-icon>

              <p
                class="main__text main__color_green"
                :class="{
                  main__opacity: currentGroup?.reach_reach === 0.2,
                  main__color_red: +currentGroup?.grow_count < 0,
                }"
              >
                {{ currentGroup?.grow_count }}
              </p>
            </div>

            <img v-else src="/icons/table/lock.svg" class="image-size_last" />
          </div>
        </div>

        <date-filter
          v-if="!isUpdatedPage"
          class="filter"
          :startValue="filterParamOnUpdate"
          @updateTable="updatePageInfo"
        ></date-filter>

        <circle-load v-else class="filter"></circle-load>
      </div>

      <statistic-cards :currentDate="setDateFilter" :groupInfo="currentGroup"></statistic-cards>

      <template v-if="currentGroup?.eye_flag">
        <div v-if="showCharts" class="charts">
          <div class="chart">
            <chart-js
              chartType="DoughnutChart"
              :chartName="createChartName('Возрастная статистика общая')"
              :params="currentGroup?.eye_data?.reach_age"
            ></chart-js>
          </div>

          <div class="chart">
            <chart-js
              chartType="PieChart"
              :chartName="createChartName('Гендерная статистика')"
              :params="createPieObject"
            ></chart-js>
          </div>

          <div class="chart" :style="{ width: '100%' }">
            <chart-js
              chartType="VerticalBarChart"
              :chartName="createChartName('Возрастная статистика')"
              :params="currentGroup?.eye_data"
            ></chart-js>
          </div>
        </div>

        <div v-if="showCharts" class="charts">
          <div class="chart">
            <chart-js
              chartType="BarChart"
              :chartName="createChartName('Данные по городам')"
              :params="currentGroup?.eye_data?.reach_cities"
            ></chart-js>
          </div>

          <div class="chart">
            <chart-js
              chartType="BarChart"
              :chartName="createChartName('Данные по странам')"
              :params="currentGroup?.eye_data?.reach_countries"
            ></chart-js>
          </div>
        </div>
      </template>

      <div v-else class="lock">
        <p class="lock__text">Графики недоступны</p>
        <img src="/icons/table/lock.svg" class="image-size_big" />
      </div>
      <div v-if="isUpdatedPage" class="lock-page" @click.stop></div>
      <close-icon
        class="close-btn"
        color="#000000"
        @click="$emit('closeWindow', { currentGroup: {}, isShow: false })"
      ></close-icon>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CloseIcon from '@/components/atom/html-icon/symbol/CloseIcon.vue';
import ArrowIcon from '@/components/atom/html-icon/arrow/ArrowIcon.vue';
import ChartJs from '@/components/common/ChartJs.vue';
// import DateFilter from '@/components/common/filter/DateFilter.vue';
import DateFilter from '@/components/user-page/DateFilter.vue';
import StatisticCards from './GroupStatisticCards.vue';
import RequestApi from '@/api/vk';
import CircleLoad from '@/components/atom/loader/Circle.vue';

export default {
  components: {
    CloseIcon,
    ArrowIcon,
    ChartJs,
    DateFilter,
    StatisticCards,
    CircleLoad,
  },

  computed: {
    ...mapGetters('vkRequests', ['getCurrentDate']),

    showCharts() {
      return !this.updateChart;
    },

    setDateFilter() {
      switch (this.filterParamOnUpdate) {
        case 'day':
          return ' за день ';
        case 'week':
          return ' за неделю ';
        default:
      }
      return ' за месяц ';
    },

    createPieObject() {
      const resultArray = {};
      let notSpecified = 0;
      notSpecified = Object.values(this.groupInfo?.eye_data?.reach_age).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );

      // resultArray['Всего'] = notSpecified;
      resultArray['Мужчины'] = Object.values(this.groupInfo?.eye_data?.reach_man_age).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );
      resultArray['Женщины'] = Object.values(this.groupInfo?.eye_data?.reach_female_age).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );
      resultArray['Не указано'] = notSpecified - resultArray['Мужчины'] - resultArray['Женщины'];

      return resultArray;
    },
  },

  data() {
    return {
      updateChart: false,
      charts: [
        {
          type: '',
          name: '',
        },
      ],

      currentGroup: { ...this.groupInfo },
      currentClass: {},
      isUpdatedPage: false,
      filterParamOnUpdate: 'day',
    };
  },

  props: {
    groupInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['closeWindow'],

  methods: {
    checkParam(item) {
      return +item === 0.1 || +item === 0.2 || +item === 0.3 ? '' : item;
    },

    setArrow(item) {
      return +item.grow_count >= 0 ? 'arrow-up' : 'arrow-down';
    },

    createChartName(title) {
      return `${title} ${this.setDateFilter}`;
    },

    async updatePageInfo(value) {
      this.filterParamOnUpdate = value;
      this.isUpdatedPage = true;
      await this.currentClass
        .updateGroupByFilter({ gid: this.currentGroup.id, filter: value })
        .then((resp) => {
          this.updateCurrentGroupInfo(resp);
          this.updateCharts();
        })
        .catch()
        .then(() => {
          this.isUpdatedPage = false;
        });
    },

    updateCurrentGroupInfo(resp) {
      let result = {};
      if (Array.isArray(resp) && resp.length !== 0) {
        const [firstItem] = resp;
        result = firstItem;
      }

      const newJSON = result;
      newJSON.subscrices = new Intl.NumberFormat('ru-RU').format(+newJSON.sub_count);
      newJSON.coverage = new Intl.NumberFormat('ru-RU').format(+newJSON.reach_reach);

      const cov = +newJSON.reach_reach / +newJSON.sub_count;
      const percent = (Number(cov.toFixed(2)) * 100).toFixed();
      newJSON.covpercent = `${percent}%`;

      newJSON.visitors = new Intl.NumberFormat('ru-RU').format(+newJSON.visitors_visitors);
      newJSON.grow = new Intl.NumberFormat('ru-RU').format(+newJSON.grow_count);

      this.currentGroup = newJSON;
    },

    updateCharts() {
      this.updateChart = true;

      this.$nextTick(() => {
        this.updateChart = false;
      });
    },
  },

  mounted() {
    this.currentClass = new RequestApi();
  },
};
</script>

<style lang="scss" scoped>
.group-statistic {
  display: flex;

  justify-content: center;
  align-items: center;

  position: relative;
  box-sizing: border-box;

  // .group-statistic__background
  &__background {
    display: block;
    content: "";

    background: #05060767;
    width: 100vw;
    height: 100vh;
  }

  &__title {
    margin: 50px 25px;

    line-height: 2.3rem;
    font-size: 2rem;

    height: auto;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-decoration: none;
  }

  &__main {
    margin: 0 45px;
  }

  // .group-statistic__information
  &__information {
    display: block;
    content: "";

    position: absolute;
    background: #ffffff;

    border-radius: 10px;

    width: 70%;
    max-width: 1600px;

    height: 80%;

    overflow: auto;
  }
}

.main {
  display: flex;
  align-items: stretch;
  justify-content: center;
  background: #ffffff;
  min-width: 590px;
  box-shadow: #bdbdbd 0px 1px 14px 8px;
  border-radius: 10px;

  &__img {
    width: 200px;
    height: 200px;
    // border: 9px solid #9b9b9b;
    border-radius: 10px;

    margin: 0 25px 0 0;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 250px;
    min-width: 250px;
    align-self: center;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 15px 0 0;
    font-size: 1rem;
  }

  &__pannel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // flex-basis: 100%;
    height: 43px;
  }

  &__color {
    &_link {
      color: #2b587a;
    }
    &_green {
      color: #7ea65a;
    }
    &_red {
      color: #d44f30;
    }
  }

  &__opacity {
    opacity: 0.6;
  }

  &__arrow {
    margin: 0 5px 0 0;
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 100%;
  }
}

.charts {
  display: flex;
  align-items: center;
  justify-content: space-around;

  flex-wrap: wrap;

  margin: 25px;
  max-width: 75%;
  margin: auto;
}
.chart {
  // flex-basis: 50%;
  min-width: 400px;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 18px;
  height: 18px;

  z-index: 30;
}

.card-panel {
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 0 55px;
  z-index: 50;
  transform: translateX(0);
}
.lock {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  width: 100%;
  &__text {
    line-height: 2rem;
    font-size: 1.5rem;
    color: #2b587a;
  }
}
.image-size {
  width: 20px;
  margin: 0 0 0 10px;
  vertical-align: middle;

  &_big {
    width: 300px;
    height: 300px;
  }

  &_last {
    margin: 0 15px 0 10px;
    width: 20px;
  }
}
.filter {
  min-width: 120px;
  width: 120px;
  height: 100%;
  align-self: flex-start;
  margin: 12px 0 0 20px;
}
.circle-load {
  width: 50px;
  height: 50px;
}

.lock-page {
  // width: 100%;
  // height: 100%;
  // background: #00010286;
  // position: absolute;

  // display: block;
  // content: "";

  // border-radius: 10px;

  // width: 70%;
  // max-width: 1600px;
  // height: 80%;
  // overflow: auto;

  background: #00010286;
  position: absolute;
  display: block;
  content: "";
  border-radius: 10px;
  width: 100%;
  max-width: 1600px;
  height: 73vh;
  overflow: auto;
  bottom: 169px;
  bottom: 0%;
  top: 353px;
}
</style>
