<template>
  <div class="chart-js">
    <component ref="chartRef" :is="setChartType" :options="result" :chartData="chartData">
    </component>
  </div>
</template>

<script>
import {
  computed, ref, onMounted,
} from 'vue';
import { DoughnutChart, BarChart, PieChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default {
  setup(props) {
    const data = ref([]);
    const data2 = ref([]);
    const label = ref([]);
    const title = ref('');
    const maxValue = ref(0);

    const chartRef = ref();
    let result = ref();

    const optionsDonut = computed(() => ({
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          maxWidth: 250,
          labels: {
            boxWidth: 20,
            font: {
              size: 16,
            },
          },
        },
        tooltip: {
          titleFont: {
            size: 20,
          },
          bodyFont: {
            size: 20,
          },
          footerFont: {
            size: 20,
          },
          callbacks: {
            label(tooltipItem) {
              return `${tooltipItem.label}`;
            },
          },
        },
        title: {
          display: true,
          text: title.value,
          font: {
            size: 20,
          },
        },
      },
    }));

    const optionsBar = computed(() => ({
      responsive: true,
      scales: {
        x: {
          ticks: {
            font: {
              size: 16,
            },
            position: 'right',
            align: 'right',
            rotation: 'right',
            labelOffset: 20,
            // maxRotation: 0,
          },
        },
        y: {
          ticks: {
            font: {
              size: 16,
            },
          },
        },
      },
      plugins: {
        display: false,
        legend: {
          position: 'right',
          display: false,

          labels: {
            // This more specific font property overrides the global property
            font: {
              size: 40,
            },
          },
        },
        title: {
          display: true,
          text: title.value,
          font: {
            size: 20,
          },
        },
        tooltip: {
          intersect: false,
          titleFont: {
            size: 20,
          },
          bodyFont: {
            size: 20,
          },
          footerFont: {
            size: 20, // there is no footer by default
          },
          callbacks: {
            label(tooltipItem) {
              const x = new Intl.NumberFormat('ru-RU').format(Math.trunc(Math.abs(Number(tooltipItem.raw))));
              return `${tooltipItem.label}: ${x}`;
            },
          },
        },
      },
    }));

    const optionsVerticalBar = computed(() => ({
      responsive: true,
      indexAxis: 'y',
      scales: {
        y: {
          stacked: true,
          ticks: {
            font: {
              size: 16,
            },
          },
        },
        x: {
          min: -maxValue.value,
          max: maxValue.value,
          stacked: true,
          display: false,
          ticks: {
            font: {
              size: 16,
            },
          },
        },
      },
      plugins: {
        display: false,
        legend: {
          position: 'right',
          display: false,

          labels: {
            font: {
              size: 40,
            },
          },
        },
        title: {
          display: true,
          text: title.value,
          font: {
            size: 20,
          },
        },
        tooltip: {
          enabled: true,
          intersect: false,
          titleFont: {
            size: 20,
          },
          bodyFont: {
            size: 20,
          },
          footerFont: {
            size: 20,
          },
          callbacks: {
            label(tooltipItem) {
              const x = new Intl.NumberFormat('ru-RU').format(Math.trunc(Math.abs(Number(tooltipItem.parsed.x))));

              return `${tooltipItem.dataset.label}: ${x}`;
            },
          },
        },
      },
    }));
    let backgroundColor = ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED'];

    let chartData = null;

    if (props.chartType === 'VerticalBarChart') {
      backgroundColor = ['#77CEFF', '#ffc0cb'];

      chartData = computed(() => ({
        labels: label.value,
        datasets: [
          {
            label: 'Мужчины',
            data: data.value,
            borderColor: backgroundColor[0],
            backgroundColor: backgroundColor[0],
          },
          {
            label: 'Женщины',
            data: data2.value,
            borderColor: backgroundColor[1],
            backgroundColor: backgroundColor[1],
          },
        ],
      }));
    } else {
      chartData = computed(() => ({
        labels: label.value,
        datasets: [
          {
            data: data.value,
            backgroundColor,
          },
        ],
      }));
    }

    switch (props.chartType) {
      case 'DoughnutChart':
      case 'PieChart':
        result = optionsDonut;
        break;
      case 'VerticalBarChart':
        result = optionsVerticalBar;
        break;
      default:
        result = optionsBar;
    }

    onMounted(() => {
      switch (props.chartType) {
        case 'DoughnutChart':
        case 'PieChart':
          Object.entries(props?.params).forEach(([key, value]) => {
            const x = new Intl.NumberFormat('ru-RU').format(Math.trunc(Math.abs(Number(value))));
            label.value.push(`${key}: ${x}`);
            data.value.push(Number(value));
          });

          break;
        case 'VerticalBarChart':
          Object.entries(props.params.reach_female_age).forEach(([key, value]) => {
            label.value.push(key.split(' ')[1]);
            data2.value.push(Number(value));
          });

          Object.values(props.params.reach_man_age).forEach((value) => {
            data.value.push(Number(-value));
          });

          maxValue.value = -Math.min(...data.value) > Math.max(...data2.value)
            ? -Math.min(...data.value)
            : Math.max(...data2.value);

          label.value.reverse();
          data.value.reverse();
          data2.value.reverse();
          break;
        default:
          Object.values(props?.params).forEach((value) => {
            label.value.push(value.city);
            data.value.push(Number(value.count));
          });
      }
      title.value = props.chartName;
      chartRef.value.update();
    });

    return {
      chartData,
      data,
      data2,
      chartRef,
      label,
      result,
      title,
      maxValue,
    };
  },
  computed: {
    setChartType() {
      switch (this.chartType) {
        case 'DoughnutChart':
          return DoughnutChart;
        case 'PieChart':
          return PieChart;
        default:
      }

      return BarChart;
    },
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      },
    },

    chartName: {
      type: String,
      default: 'График',
    },
    chartType: {
      type: String,
      default: 'DoughnutChart',
    },
  },
  components: {
    DoughnutChart,
    BarChart,
    PieChart,
  },
};
</script>
