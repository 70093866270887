<template>
  <div :class="arrow">
  </div>
</template>

<script>
export default {
  props: {
    arrow: {
      type: String,
      default: 'arrow-down',
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
}
.arrow-up {
  position: relative;
  height: 0px;
  width: 0px;
  border: 5px solid;
  border-color: #7ea65a #7ea65a transparent transparent;
  transform: rotate(-45deg);

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    height: 5px;
    width: 8px;
    background-color: #7ea65a;
    transform: rotate(-45deg) translate(2px, 1px);
  }
}

.arrow-down {
  position: relative;
  height: 0px;
  width: 0px;
  border: 5px solid;
  border-color: #d44f30 #d44f30 transparent transparent;
  transform: rotate(135deg);

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    height: 5px;
    width: 8px;
    background-color: #d44f30;
    transform: rotate(-45deg) translate(2px, 1px);
  }
}
</style>
